import React, {useRef, useEffect, createRef} from "react";
import { Seo } from "../../components/Seo";
import {graphql} from 'gatsby';
import Carousel from "../../components/Projects/Carousel";
import { StaticImage } from "gatsby-plugin-image";
import ContactForm from "../../components/ContactForm";

const RonnieProject = ({data}) => {
  const textRef = useRef([]);
  const imageRef = useRef([]);
  for(let i = 0; i < 3; i++) {
    textRef.current[i] = createRef();
    imageRef.current[i] = createRef();
  }

  const resizeSection = () => {
    for(const[k,i] of Object.entries(textRef.current)) {
      if(i.current || false) {
        let mheight = i.current.offsetHeight + 40;
        
        imageRef.current[k].current.style.maxHeight = `${mheight}px`;
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resizeSection);
    resizeSection();
  }, []);

  const projects = data.allPrismicMixedContentProject.edges.map((i,k) => ({
    to: i.node.uid,
    name: i.node.data.title,
    description: i.node.data.description,
    src: i.node.data.cover_image.url
  }))
  return (
    <main>
      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[0]}
            className="py-24 px-16 xl:px-32">
            <h1 className="font-black text-4xl lg:text-5xl mb-12">
              Ronnie & Co.<br />
              More good days.<br />
              Less bad days.
            </h1>
            <p className="text-xl lg:text-2xl mb-8">
              Ronnie & Co is an iconic small business success story. A mother with a passion to improve people’s lives, one day at a time. Her range of bespoke gratitude journals and planners that sell out each year, are made with thought and love and of course manufactured with Sourci’s help.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              In August 2020, Rhonda reached out to Sourci to make a critical change in her business and redevelop her supply-chain. Rhonda was previously manufacturing her range of products locally, with local costs leaving her with some very undesirable margins. Sourci understood the importance of maintaining her high quality product costs, whilst reducing her production costs significantly so she could scale and grow her business.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              After 4 months of researching and sampling, Ronnie & Co were ready to hit “GO” on their first ever international stock order. Sourci had secured a reputable factory who had not only recreated the journal Rhonda was wanting, but one who also made improvements on her original brief.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              With Sourci’s help, Ronnie & Co was able to experience a better quality product, and a more competitive price, all whilst working with her Melbourne based account manager, providing her full support and guidance for her first time manufacturing overseas, pretty impressive huh?
            </p>
            <p className="font-bold text-xl lg:text-2xl">Here's how we made amazing happen for Ronnie & Co.</p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[0]}>
              <StaticImage src="../../images/ronnie-co-1.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container grid-cols-1 lg:grid-cols-3 mx-auto px-6 grid text-center gap-x-12 gap-y-12">
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>&#8595;1%</span>
            </p>
            <p className="font-black text-xl">DEFECT RATE</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>&#8595;60%</span>
            </p>
            <p className="font-black text-xl">COST OF GOODS</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>ZERO</span>
            </p>
            <p className="font-black text-xl">HEADACHES</p>
          </div>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div className="flex h-full"
            ref={imageRef.current[1]}>
              <StaticImage src="../../images/ronnie-co-2-2175w.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
          <div ref={textRef.current[1]}
            className="py-24 px-16 xl:px-32">
            <h3 className="font-black text-2xl lg:text-3xl mb-12">
              Industry leading defect rate
            </h3>
            <svg className="mb-8" width="270" height="135" viewBox="0 0 179 90" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 0V73H179" stroke="black"></path><rect x="11" y="47.45" width="64" height="25.549999999999997" fill="black"></rect><rect x="109" width="64" fill="#F27BB7" y="70.81" height="2.19" stroke="black"></rect><text x="140" y="68" fill="black" font-weight="900" font-size="22px" text-anchor="middle" stroke="black">1%</text><text x="43" y="68" fill="#F27BB7" font-weight="900" font-size="22px" text-anchor="middle">30%</text><text x="43" y="87" fill="black" font-weight="bold" font-size="11px" text-anchor="middle">ALONE</text><text x="140" y="87" fill="#F27BB7" font-weight="bold" font-size="11px" text-anchor="middle">WITH SOURCI</text></svg>
            <p className="text-xl lg:text-2xl mb-8">
              After successfully delivering Ronnie & Co’s first shipment, Sourci began immediately working on the following years’ Journal as well as expanding Rhonda’s range into other products you now see on her website. Sourci has become her go to supply-chain partner when it comes to developing and bringing out widely successful products.
            </p>
            <p className="font-bold text-xl lg:text-2xl">We heart control.</p>
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container mx-auto px-6 text-center">
          <h3 className="font-black text-5xl lg:text-6xl">
            Sourci puts product sourcing wizardry at the heart of your team. Push the button.
            <span className="block text-sourci-pink">Make amazing happen.</span>
          </h3>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[2]}
            className="py-24 px-16 xl:px-32">
            <h3 className="font-black text-2xl lg:text-3xl mb-12">
              How product sourcing wizardry reduced Ronnie & Co cost of goods by 60%
            </h3>
            <p className="text-xl lg:text-2xl mb-8">
              Production cost is built up across the direct materials, labour and then the factory/manufacturing overheads. Rhonda had high material and labour costs due to manufacturing locally. Sourci was able to research and negotiate lower costs, while still maintaining the quality.
            </p>
            <p className="font-bold text-xl lg:text-2xl">You're in charge here.</p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[2]}>
              <StaticImage src="../../images/ronnie-co-3-2175w.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-32">
        <div className="xl:container lg:grid lg:grid-cols-2 mx-auto px-6">
          <div className="text-black">
            <p className="text-4xl md:text-6xl font-black mb-8">The time<br /> is now.</p>
            <p className="text-black text-4xl mb-8 font-black">Let's make amazing<br /> happen today.</p>
            <p className="text-3xl mb-2">
              <a href="tel:+611300768724"
                className="hover:underline">1300 SOURCI</a>
            </p>
            <p className="text-3xl mb-2">
              <a href="mailto:hello@sourci.com.au"
                className="hover:underline">hello@sourci.com.au</a>
            </p>
          </div>
          <div className="mt-12 lg:mt-0">
            <ContactForm bg="light"
              location="Case Study - Ronnie & Co." />
          </div>
        </div>
      </section>
      <Carousel projects={projects}
        project="ronnie-co"
        title="Check out our other Projects" />
    </main>
  )
}

export const query = graphql`
  query {
    allPrismicMixedContentProject {
      edges {
        node {
          data {
            title
            description
            cover_image {
              url
            }
          }
          uid
        }
      }
    }
  }
` 

export default RonnieProject;

export function Head() {
  return (
    <Seo title="Ronnie & Co. | Our Projects | Sourci"
      description="More good days, less bad days with Ronnie & Co's range of gratitude journals."
      canonical="/projects/ronnie-co" />
  )
}